<template>
  <el-main class="simple2-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        揮棒數據做公益
      </div>
      <p>2023年2月 </p>
      <div class="image-container">
        <img :src="image" class="image" alt="圖為Basepara棒球學校謝秉恆執行長進行揮棒數據分析解讀">
        <p class="image_desc" aria-hidden="true">圖為Basepara棒球學校謝秉恆執行長進行揮棒數據分析解讀</p>
        <p class="image_desc">(圖片提供：Basepara棒球學校)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">公益目標</div>
        <div class="content-desc">
          台灣有非常深厚的棒球底蘊，不過進入的門檻較一般運動為高。希望改善訓練環境，讓更多民眾願意投入棒球活動，進而帶動利用台灣運動數據回饋捐贈做公益之創新模式，推動更多創新與數據利他價值應用。 
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動 </div>
        <div class="content-desc">
          本計畫與Basepara、女棒運動推廣協會一同辦理運動數據公益驗證推廣活動，「揮棒數據做公益」棒球數據體驗活動，由產業署胡貝蒂副署長與資策會林玉凡院長等長官一同體驗與見證，並邀請女棒國手陳禹璇、陳郁霈一同倡議，捐出標竿訓練數據，而吸引更多人關注。 
        </div>
        <br>
        <div class="content-desc">
          於民國111年12月24日至民國112年2月10日，共完成累計2,062人次及13,885筆揮棒數據，其中包含揮棒速度、手腕速度、揮擊時間、攻擊角度、球棒垂直角度、平面重合率、旋轉加速度、早期連接性、擊球點連接性、功率輸出等打擊揮棒數據，完整數據留存原始資料於貝思沛拉棒球學校內，並已與運動數據公益平台串接。  
        </div>
      </div>
      <div class="desk-pad-view">
        <div class="image-container">
          <el-row>
            <el-col :span="12">
              <img :src="image2" class="image-100" alt="圖片為女棒國手(陳禹璇)參加「揮棒數據做公益活動」進行揮棒動作示範與數據捐贈" aria-hidden="true">
              <p class="image_desc">女棒國手(陳禹璇)參加「揮棒數據做公益活動」進行揮棒動作示範與數據捐贈</p>
              <p class="image_desc">(圖片提供：Basepara 棒球學校)</p>
            </el-col>
            <el-col :span="12">
              <img :src="image3" class="image-100" alt="圖片為女棒國手(陳郁霈)參加「揮棒數據做公益活動」配戴感應式裝置進行揮棒示範" aria-hidden="true">
              <p class="image_desc">女棒國手(陳郁霈)參加「揮棒數據做公益活動」配戴感應式裝置進行揮棒示範</p>
              <p class="image_desc">(圖片提供：Basepara 棒球學校)</p>
            </el-col>
          </el-row>
        </div>
        
      </div>
      <div class="phone-view">
        <div class="image-container">
          <img :src="image2" class="image" alt="" aria-hidden="true">
          <p class="image_desc">女棒國手(陳禹璇)參加「揮棒數據做公益活動」進行揮棒動作示範與數據捐贈</p>
          <p class="image_desc">(圖片提供：Basepara 棒球學校)</p>
        </div>
        <div class="image-container">
          <img :src="image3" class="image" alt="" aria-hidden="true">
          <p class="image_desc">女棒國手(陳郁霈)參加「揮棒數據做公益活動」配戴感應式裝置進行揮棒示範</p>
          <p class="image_desc">(圖片提供：Basepara 棒球學校)</p>
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據公益模式</div>
        <div class="content-desc">
          專業運動員數據訓練與倡議，分析女棒標竿球星數據，訓練球員與強化女性棒球產業，取得相關打擊公益數據後，包括揮棒速度、手腕速度、攻擊角度…等數據，女生棒球運動族群便可以了解該族群的揮棒速度其最大值與平均值、自己的成績在族群中的程度落點、以及女生與男生族群之間的差異。即可進一步分析並優化設計出適合女生的運科訓練方法，包括:熱身方式、活動度優化、打擊動力鏈機制調整、甚至配合女生的生理週期進行體能訓練上的個人化調整，優化後的服務回饋給女子棒球運動參與者。透過棒球運科方法論，強化訓練輔具的開發，如：智慧發球機、VR模擬發球軟體改良。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">公益展望</div>
        <div class="content-desc">
          透過數據收集，讓不運動的人開始運動，提供弱勢或特殊族群(如：老人、幼童…等)的友善運動資源或現狀改善，作為動機誘發的參考依據，從而讓運動產業發展出合適的應用服務。透過實證活動與民眾互動，倡議「數據利他」議題吸引民眾參與運動、同時捐贈數據，使數據用在運動研究、社會創新等促動全民參與數據公益產生創新運科應用，結合運動數據回饋，建構永續運營生態圈。 
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11102',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/sample_2.png'),
      image2: require('@/assets/images/sample/baseball_2.png'),
      image3: require('@/assets/images/sample/baseball_3.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple2-container{
  .desk-pad-view{
    @include hidden-by-phone;
  }
  .phone-view{
    @include hidden-by-desk;
    @include hidden-by-pad;
  }
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
      padding: 0 15px;
    }
    .image-100 {
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
